<template>
  <CRow>
    <CCol sm="8">
      <CCard>
        <CCardHeader>
          <b>Category</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <dt class="col-sm-2">ID</dt>
            <dd class="col-sm-10">{{ category.id }}</dd>

            <dt class="col-sm-2">Name</dt>
            <dd class="col-sm-10">{{ category.name }}</dd>

            <dt class="col-sm-2">Subcategories</dt>
            <dd class="col-sm-10">
              <template v-for="subcategory in category.subcategories">
                <CBadge :color="`secondary`" class="mr-1" :key="subcategory.id">
                  {{ subcategory.name }}
                </CBadge>
              </template>
            </dd>

            <dt class="col-sm-2">Status</dt>
            <dd class="col-sm-10">
              <CBadge :color="getBadge(category.status)">
                {{ category.status }}
              </CBadge>
            </dd>

            <dt class="col-sm-2">CreatedDate</dt>
            <dd class="col-sm-10">{{ category.created_at }}</dd>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACTION_FETCH_CATEGORY } from '@/store/actions.type';

export default {
  name: 'Category',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes('categories');
    });
  },
  data() {
    return {};
  },
  mounted() {
    const id = this.$route.params.id;
    this.$store.dispatch(ACTION_FETCH_CATEGORY, id);
  },
  computed: {
    ...mapGetters(['category']),
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case 'active':
          return 'success';
        case 'inactive':
          return 'secondary';
        default:
          'primary';
      }
    },
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: '/categories' });
    },
  },
};
</script>
